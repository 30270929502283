import Vue from 'vue';
import algoliasearch from 'algoliasearch';

export const searchClient = algoliasearch(
  '05XAX70WF8',
  'c75fc1942a6dfd75a8c4692dae60c549'
);

const initialState = {
  searchState: {
    query: '',
  },
};

const state = Vue.observable(initialState);

export const searchStateComputed = () => ({
  searchState: {
    get() {
      return state.searchState;
    },
  },
});

export const setSearchState = (value) => {
  state.searchState = value;
};

// export const syncIndex = searchClient.initIndex('syncs_2');
export const syncIndex = searchClient.initIndex('syncs-prod');
export const songsIndex = searchClient.initIndex('test_index');
export const versionsIndex = searchClient.initIndex('versions-prod');
export const songsSearchIndex = searchClient.initIndex('song_search2');
// export const songsIndex = searchClient.initIndex('sample-data');
export const CATALOG_INDEX_NAME = 'rhio_production_db';
export const catalogIndex = searchClient.initIndex(CATALOG_INDEX_NAME);
export const publishersIndex = searchClient.initIndex('publishers');
export const publishingPartiesIndex = searchClient.initIndex(
  'publishing_parties'
);
export const initAlgoliaIndex = (index) => searchClient.initIndex(index);
